import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import formatPhone from "../helpers/formatPhone";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import ThemeContext from '../context/ThemeContext';
import LocationCard from "../components/locationCard";
import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./appointments.module.scss"

const AppointmentsTemplate = (props) => {

	const {
		data: {
			appointmentsPage,
			appointmentsPage: {
				title,
				ACFAppointments: {
					appointmentsInfo: {
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							}
						},
						appointmentsContent
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			locations: {
				nodes: locations
			},
			rightArrowYellowOffsite,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			rightArrowGray,
			arrowRedLocal,
			locationIcon,
		}
	} = props;

	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}


		getLocation();
		sortByDistance();

	}, [location, locations, getLocation])

	function directionLink() {
		if (!nearestLoc.birdeyeLocation.location.lat || !nearestLoc.birdeyeLocation.location.lng) {
			return;
		}

		let placeIdParam = '';

		if (nearestLoc.ACFLocation.googlePlaceId) {
			placeIdParam = `&destination_place_id=${nearestLoc.ACFLocation.googlePlaceId}`;
		}

		return <a className={styleSheet.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${nearestLoc.birdeyeLocation.location.lat}, ${nearestLoc.birdeyeLocation.location.lng}${placeIdParam}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowYellowOffsite} passedClass={styleSheet.icon} /></a>;
	}

	return (
		<Layout showMobileCTA={false}>

			<Seo post={appointmentsPage} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						
						<div className={styleSheet.NearestLocation}>
							<div className={styleSheet.topbar}>
								<span className={styleSheet.myloc}><FixedImage image={locationIcon} passedClass={styleSheet.icon} /> My location</span>
							</div>
							{ nearestLoc === null && (
								<div className={styleSheet.top}>
									<span className={styleSheet.title}>Loading...</span>
								</div>
							)}
							{
								nearestLoc !== null && (
									<>
										<div className={styleSheet.top}>
											<Link to={nearestLoc.link} className={styleSheet.title} dangerouslySetInnerHTML={{__html: nearestLoc.title}}></Link>
										</div>
										<div className={styleSheet.bottom}>
											{ nearestLoc.birdeyeLocation.location.address1 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address1 }}></span>}
											{ nearestLoc.birdeyeLocation.location.address2 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address2 }}></span>}
											{nearestLoc.birdeyeLocation.location.zip && nearestLoc.birdeyeLocation.location.state && nearestLoc.birdeyeLocation.location.city && <span className={styleSheet.address} dangerouslySetInnerHTML={{ __html: `${nearestLoc.birdeyeLocation.location.city}, ${nearestLoc.birdeyeLocation.location.state} ${nearestLoc.birdeyeLocation.location.zip}` }}></span>}
											{ directionLink() }
											{ nearestLoc.birdeyeLocation.phone && <a className={styleSheet.phone} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>{formatPhone(nearestLoc.birdeyeLocation.phone)}</a>}
											{ nearestLoc.ACFLocation.bookMyAppointmentLink && <WPLink link={nearestLoc.ACFLocation.bookMyAppointmentLink} passedClass={styleSheet.link} icon={arrowRedLocal} />}
											{ !nearestLoc.ACFLocation.bookMyAppointmentLink && nearestLoc.birdeyeLocation.phone && <a className={styleSheet.link} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>Call Now</a>}
										</div>
									</>
								)
							}
						</div>
					</div>
					<div className={styleSheet.leftbottom}>
						
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.servicename} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}}data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				{ appointmentsContent && <div className="wpcontent" dangerouslySetInnerHTML={{__html: appointmentsContent}}></div> }
				<div className={styleSheet.locationcontainer}>
					{ locations && locations.map((location, i) => {   
						return (
							<div key={location.id} className={styleSheet.location}>
								<LocationCard key={location.id} location={location} arrowRedLocal={arrowRedLocal} rightArrowYellowOffsite={rightArrowYellowOffsite} isTeamPage={false} isAppointmentsPage={true} rightArrowGray={rightArrowGray} />
							</div>
						);
					})} 
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  appointmentsPage: wpPage(id: {eq: $id}) {
    id
    title
    ACFAppointments {
      appointmentsInfo {
        right {
          introContent {
            blurb
            heading
          }
        }
        appointmentsContent
      }
    }
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowGray: file(name: {eq: "arrow_gray_local"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  locationIcon: file(name: {eq: "location_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 14, layout: FIXED)
    }
  }
  locations: allWpLocation(sort: {fields: title}) {
    nodes {
      id
      link
      title
      birdeyeLocation {
				businessId
				timezone
				hoursOfOperations {
					day
					isOpen
					workingHours {
						startHour
						endHour
					}
				}
				location {
					lat
					lng
					address1
					address2
					city
					state
					zip
				}
				name
				phone
			}
      ACFLocation {
				googlePlaceId
        bookMyAppointmentLink {
          target
          title
          url
        }
      }
    }
  }
}
`;

export default AppointmentsTemplate;
